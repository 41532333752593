import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import _ from '@/lodash';
import loginStyles from '../Login/Login.module.scss';
import styles from './SignUpTherapist.module.scss';
import Tooltip from '@/components/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import Evil from '@/components/Evil/Evil';
import { fetchPost } from '@/hooks/useFetch';
import Modal from '@/components/Modal/Modal';
import LegalPrivacy from '../LegalPrivacy/LegalPrivacy';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { loginCallback } from '../Login/loginHelpers';
import { useDispatch } from 'react-redux';
import LegalTerms from '../LegalTerms/LegalTerms';
import DefaultRedirect from '@/containers/DefaultRedirect/DefaultRedirect';

function SignUpTherapist() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsCheck, setTermsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRobot, setIsRobot] = useState(false);

  const isFilled = Boolean(email && password && termsCheck);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    // https://thecopenhagenbook.com/password-authentication
    if (_.size(password) < 8) {
      setErrorMessage('Please use a password with at least 8 characters.');
      return;
    }

    // https://security.stackexchange.com/questions/39849/does-bcrypt-have-a-maximum-password-length
    if (_.size(password) >= 50) {
      setErrorMessage('Please use a password with less than 50 characters.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    fetchPost('/auth/register', { email, password })
      .then((res) => {
        ReactGA.event({ category: 'Conversion', action: 'Directory Signup' });
        loginCallback({ res, dispatch, navigate });
      })
      .catch(() => {
        setErrorMessage(
          'Something went wrong. Please try a different email or try again later.',
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={styles.signupPage}>
      <HeaderTags
        title="Get Listed | MintLeaf"
        description="Sign up to get listed on our free therapist directory and get new clients."
        url="https://mintleaf.co/register"
      />

      <DefaultRedirect />

      <div>
        <h1 className={styles.signupHeader}>Get started with MintLeaf.</h1>

        <div className={styles.signupSubheader}>
          MintLeaf helps you grow your private practice. Let us help you find
          clients that agree with your approach to mental health.
        </div>
      </div>

      <form onSubmit={onSubmit} className={styles.signupForm}>
        <div className={loginStyles.loginFields}>
          <div className="form-field">
            <span className="form-label">Email</span>
            <input
              name="email"
              type="email"
              className={loginStyles.loginInput}
              placeholder="Your Email"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />
          </div>

          <div className="form-field">
            <span className="form-label">Password</span>
            <input
              name="password"
              type="password"
              className={loginStyles.loginInput}
              placeholder="Your Password"
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
            />
          </div>
        </div>

        <Evil setIsRobot={setIsRobot} />

        <label className={loginStyles.terms}>
          <input
            type="checkbox"
            checked={termsCheck}
            onChange={(event) => setTermsCheck(event.target.checked)}
          />
          I have read and accepted the{' '}
          <Modal
            buttonChildren={<>Terms of Use</>}
            buttonClassName="link"
            render={({ closeButton }) => (
              <div className={loginStyles.legalModal}>
                {closeButton}
                <LegalTerms />
              </div>
            )}
          />
          {' and '}
          <Modal
            buttonChildren={<>Privacy Policy</>}
            buttonClassName="link"
            render={({ closeButton }) => (
              <div className={loginStyles.legalModal}>
                {closeButton}
                <LegalPrivacy />
              </div>
            )}
          />
          .
        </label>

        {errorMessage && (
          <div className={loginStyles.loginError}>{errorMessage}</div>
        )}

        <div className={loginStyles.passwordRow}>
          <Tooltip
            content="Please fill out all fields and checkboxes."
            disabled={isFilled}
          >
            <Button
              className="btn"
              type="submit"
              disabled={isLoading || !isFilled}
            >
              Create Profile
            </Button>
          </Tooltip>
        </div>

        <div className={loginStyles.switchContainer}>
          Already have an account?
          <a href="/login" className="link green">
            Login
          </a>
        </div>
      </form>
    </div>
  );
}

export default SignUpTherapist;
