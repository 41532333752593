import { Button } from '../Button/Button';
import styles from './Test.module.scss';
import _ from '@/lodash';
import { useForm } from 'react-hook-form';
import Radio from '../Radio/Radio';
import {
  allStates,
  tests,
  useAbsoluteUrl,
  valuesToOptions,
  Option,
  getStateUrl,
} from '@/helpers';
import {
  IoIosArrowRoundForward,
  IoIosCheckboxOutline,
  IoIosTimer,
} from 'react-icons/io';
import { useState } from 'react';
import { scrollToTop } from '@/hooks/ScrollToTop';
import { HeaderTags } from '../HeaderTags/HeaderTags';
import { Link, useNavigate } from 'react-router-dom';
import Select from '../Select/Select';

interface TestProps {
  test: (typeof tests)[0];
}

export default function Test(props: TestProps) {
  const {
    test: {
      title,
      subTitle,
      directions,
      sources,
      questions,
      allOptions,
      results,
      slug,
    },
  } = props;

  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm();
  const maxScore = _.max(_.map(_.map(allOptions, 'value'), _.toNumber)) || 0;
  const maxTotalScore = maxScore * questions.length;

  const onSubmit = (formData: any) => {
    const newScore = _.sum(_.map(_.values(formData), _.toNumber));

    setScore(newScore);
    setShowResult(true);
    scrollToTop();
  };

  const result = _.find(
    results,
    ({ min, max }) => score >= min && score <= max,
  );

  const onChangeState = (option: Option) => {
    navigate(getStateUrl(option.value, slug));
  };

  return (
    <div className={styles.testPage}>
      <HeaderTags
        title={`${title} / Quiz | MintLeaf`}
        description={subTitle}
        url={useAbsoluteUrl()}
      />

      {!showResult && (
        <div>
          <div className={styles.pageTitle}>{title}</div>

          <div>
            <div className={styles.testTag}>
              <IoIosCheckboxOutline />
              <span>{questions.length} Questions</span>
            </div>
            <div className={styles.testTag}>
              <IoIosTimer />
              <span>{Math.ceil(questions.length * 0.25)} Minutes</span>
            </div>
          </div>

          <div className={styles.pageSubtitle}>{subTitle}</div>
        </div>
      )}

      {showResult && (
        <div className={styles.resultContainer}>
          <div className={styles.resultTitle}>Your {title} Score</div>

          <div className={styles.scoreBarRow}>
            <div className={styles.scoreBound}>0</div>
            <div className={styles.scoreBar}>
              <div
                className={styles.scoreTarget}
                style={{ left: `${(score * 100) / maxTotalScore}%` }}
              >
                {score}
              </div>
            </div>
            <div className={styles.scoreBound}>{maxTotalScore}</div>
          </div>

          <div className={styles.resultGrid}>
            {_.map(results, ({ min, max, label }) => {
              const isResult = result?.label === label;
              const resultClass = isResult
                ? styles.resultRowActive
                : styles.resultRow;

              return (
                <div className={resultClass}>
                  <div>
                    {min}-{max}
                  </div>
                  <div>{label}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {showResult && (
        <div className={styles.explainContainer}>
          <div className={styles.explainTitle}>Your Results</div>

          <div className={styles.explainSubtitle}>
            What do your results mean?
          </div>

          {_.map(result?.description, (line) => (
            <div>{line}</div>
          ))}

          <div className={styles.explainSubtitle}>What can you do next?</div>

          <div>
            This test is often used as a screening tool in clinical settings,
            however we have provided it here only for educational purposes. To
            get proper support or diagnosis from a mental health professional
            you can use our search tool below to reach out to a therapist.
          </div>

          <div>
            We let each therapist answer questions about specific concerns like
            anxiety or depression, this way you can find somebody that talks
            about these issues in a way that resonates with you before you reach
            out.
          </div>

          <div className={styles.testCTA}>
            <Select
              options={valuesToOptions(allStates)}
              value={null}
              onChange={onChangeState}
              placeholder="Find Therapists by State"
              isSearchable
            />
          </div>

          <div className={styles.testFooter}>
            <div className={styles.testFooterTitle}>
              Sources & Acknowledgements
            </div>
            <div>{sources}</div>
          </div>
        </div>
      )}

      {!showResult && (
        <div className={styles.testContainer}>
          <div className={styles.testDirections}>{directions}</div>

          {_.map(questions, (question, index: number) => (
            <div className={styles.questionRow} key={index}>
              <div className={styles.questionText}>
                {index + 1}. {question}
              </div>

              <Radio
                inputProps={register(_.toString(index), {
                  required: 'Field is required',
                })}
                allOptions={allOptions}
              />
            </div>
          ))}

          <div className={styles.testActions}>
            <Button
              className={styles.testSubmit}
              color="green"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid}
            >
              <span>See Results</span>
              <IoIosArrowRoundForward />
            </Button>
          </div>

          <div className={styles.testFooter}>
            <div className={styles.testFooterTitle}>Who is this test for?</div>
            <div>
              This test is designed for adults as an initial screening tool for
              educational purposes. This test is not intended to be diagnostic.
              For professional mental health help or support please use our tool
              to{' '}
              <Link to="/therapists" className="link">
                Find a Therapist
              </Link>
              .
            </div>
            <div>
              We do not save or use your answers beyond its use in this test.
            </div>
          </div>

          <div className={styles.testFooter}>
            <div className={styles.testFooterTitle}>
              Sources & Acknowledgements
            </div>
            <div>{sources}</div>
          </div>
        </div>
      )}
    </div>
  );
}
