import _ from '@/lodash';
import { format as formatFn } from 'date-fns';
import { TagDoc, UserDoc } from '../src-server/models';
import { fetchGet, rawFetch } from './hooks/useFetch';
import { useLocation } from 'react-router-dom';
import { useDocListSelector, useLoginSelector } from './redux/selectors';
import { UAParser } from 'ua-parser-js';
import { captureException } from '@sentry/react';
import { isProduction } from './hooks/Tracker';
import { useMemo } from 'react';

export function aOrAn(text: string) {
  const vowels = /^[aeiou]/i;
  return vowels.test(text) ? 'an' : 'a';
}

export function useAbsoluteUrl() {
  const { pathname } = useLocation();
  return `https://mintleaf.co${pathname}`;
}

export function isMobile() {
  const parser = new UAParser();
  return parser.getDevice().type === 'mobile';
}

export function isChromium() {
  const parser = new UAParser();
  const chromiumBased = ['Chrome', 'Chromium', 'Edge'];

  return _.includes(chromiumBased, parser.getBrowser().name);
}

export function formatDate(date: Date, format = 'MMM d, yyyy') {
  return formatFn(new Date(date), format);
}

export function formatPhone(phone: string) {
  const nonDigit = /\D/g;
  const numbersOnly = phone.replace(nonDigit, '');
  let localNumber = numbersOnly;

  if (_.size(numbersOnly) === 11 && _.first(numbersOnly) === '1') {
    localNumber = numbersOnly.substring(1);
  }

  if (_.size(localNumber) === 10) {
    const sliceOne = _.slice(localNumber, 0, 3).join('');
    const sliceTwo = _.slice(localNumber, 3, 6).join('');
    const sliceThree = _.slice(localNumber, 6).join('');

    return `(${sliceOne}) ${sliceTwo}-${sliceThree}`;
  }

  return phone;
}

export function getProfileLink(userDoc: Partial<UserDoc>) {
  const { url_tag, short_id } = userDoc;
  const url = url_tag
    ? `/therapist/${url_tag}/${short_id}`
    : `/therapist/${short_id}`;

  return url;
}

export function useDefaultLink() {
  const login = useLoginSelector();
  const short_id = login?.short_id || '';
  const url_tag = login?.url_tag || '';

  return short_id ? getProfileLink({ url_tag, short_id }) : '/';
}

export function getDisplayName(userDoc: UserDoc) {
  const { first_name, last_name } = userDoc;

  return first_name ? `${first_name} ${last_name}` : 'N/A';
}

export function hasTeletherapy(userDoc: UserDoc) {
  const { teletherapy, online_therapy } = userDoc;

  return online_therapy
    ? online_therapy === 'Both' || online_therapy === 'Teletherapy Only'
    : teletherapy;
}

export function hasInPerson(userDoc: UserDoc) {
  const { city, zip, online_therapy } = userDoc;

  return online_therapy
    ? online_therapy === 'Both' || online_therapy === 'In-Person Only'
    : city || zip;
}

export function getShortLocation(userDoc: UserDoc) {
  const { city, state, licensed_states } = userDoc;
  let locations;

  if (state) {
    locations = _.compact([city, state]);
  } else {
    locations = _.compact([_.first(licensed_states)]);
  }

  return locations.join(', ');
}
export function getShortHeadline(userDoc: UserDoc) {
  const { headline } = userDoc;

  return _.truncate(headline, { length: 50 });
}

export function cleanString(rawString?: string) {
  return _.toLower(_.trim(rawString));
}

export function getSlug(string = '') {
  return _.kebabCase(_.trim(shortenDC(string)));
}

export function getTagUrl(tagLabel: string) {
  return `/therapists/${getSlug(tagLabel)}`;
}

export function getStateUrl(state: string, tagLabelOrSlug?: string) {
  let stateUrl = `/therapists/${getSlug(state)}`;

  if (tagLabelOrSlug) {
    stateUrl += `/${getSlug(tagLabelOrSlug)}`;
  }

  return stateUrl;
}

export function getSlugOption(values: string[], slug?: string | null) {
  const optionValue = _.find(values, (value) => slug === getSlug(value)) || '';
  return valuesToOptions(optionValue) as Option;
}

export function getTagSlugOption(tagDocs: TagDoc[], slug?: string | null) {
  const optionValue = _.find(tagDocs, (tagDoc) => slug === tagDoc.slug) || null;
  return tagsToOptions(optionValue) as Option;
}

export async function uploadToS3(
  presignEndpoint: string,
  presignParams: any,
  data: any[],
) {
  if (data) {
    const presignedUrls = await fetchGet(presignEndpoint, presignParams);

    const uploads = _.map(presignedUrls, ({ url, fields }, index: number) => {
      const formData = new FormData();

      _.each(fields, (value, key) => {
        formData.set(key, value);
      });

      formData.set('file', data?.[index]);

      return rawFetch(url, {
        method: 'POST',
        body: formData,
      }).catch((error) => {
        captureException(error, { extra: { url } });
      });
    });

    return Promise.all(uploads);
  }
}

export interface Option {
  label: string;
  value: string;
}

export function shortenDC(label: string) {
  return label === 'District of Columbia' ? 'DC' : label;
}

export const valuesToOptions = (input: string[] | string | null) => {
  if (!input) {
    return;
  }

  if (_.isArray(input)) {
    return _.map(input, (inputElement) => {
      return { value: shortenDC(inputElement), label: inputElement };
    });
  }

  return { value: shortenDC(input), label: input };
};

export const tagsToOptions = (input: TagDoc[] | TagDoc | null) => {
  if (!input) {
    return;
  }

  return _.isArray(input)
    ? _.map(input, ({ id, label }) => ({ value: id, label: label }))
    : { value: input.id, label: input.label };
};

export const shortGenders = ['Female', 'Male', 'Non-Binary'];
export const allGenders = ['Female', 'Male', 'Transgender', 'Non-Binary'];

// 80+ therapists - 16 states
export const insuranceStates = _.sortBy([
  'Texas',
  'Florida',
  'California',
  'New York',
  'Colorado',
  'New Jersey',
  'Arizona',
  'Maryland',
  'Illinois',
  'Washington',
  'Georgia',
  'Pennsylvania',
  'North Carolina',
  'Ohio',
  'Virginia',
  'Massachusetts',
]);

// 40+ therapists - 32 states
export const tagStates = _.sortBy([
  'Texas',
  'Florida',
  'California',
  'New York',
  'Colorado',
  'New Jersey',
  'Arizona',
  'Maryland',
  'Illinois',
  'Washington',
  'Georgia',
  'Pennsylvania',
  'North Carolina',
  'Ohio',
  'Virginia',
  'Massachusetts',
  'Michigan',
  'Delaware',
  'Oregon',
  'Minnesota',
  'Nevada',
  'Tennessee',
  'New Mexico',
  'Connecticut',
  'South Carolina',
  'Idaho',
  'District of Columbia',
  'Utah',
  'Indiana',
  'Kansas',
  'Iowa',
  'Missouri',
]);

export const allStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const statesToAbbrev: any = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const allAgeFocuses = [
  'Toddler',
  'Children (6-10)',
  'Preteen',
  'Teen',
  'Adult',
  'Elders (65+)',
];

export const allEthnicityFocuses = [
  'Asian',
  'Black or African American',
  'Hispanic or Latin',
  'Indian',
  'Middle Eastern',
  'Native American',
  'Pacific Islander',
];

export const allSexualityFocuses = [
  'LGBTQ+',
  'Lesbian',
  'Gay',
  'Bisexual',
  'Transgender',
  'Non-binary',
  'Asexual',
];

const nationalInsurances = [
  'Aetna',
  'Anthem',
  'Blue Cross Blue Shield (BCBS)',
  'Cigna',
  'Humana',
  'Medicaid',
  'Medicare',
  'Molina',
  'Optum',
  'Oscar',
  'Tricare',
  'UnitedHealthcare (UHC / UBH)',
];

const stateToInsurances: any = {
  Alabama: ['Great-West Life'],
  Alaska: ['Moda Health', 'Premera', 'Great-West Life'],
  Arkansas: ['QualChoice'],
  California: ['Kaiser (Out-of-Network)'],
  Colorado: ['Kaiser (Out-of-Network)'],
  Connecticut: [
    'Connecticare',
    'Harvard Pilgrim',
    'Tufts Health Plan',
    'Emblem Health',
    'Oxford Health Plans',
  ],
  DC: ['CareFirst'],
  'District of Columbia': ['CareFirst'],
  Delaware: ['Great-West Life', 'Highmark'],
  Florida: ['Florida Blue', 'Magellan Health'],
  Georgia: ['Kaiser (Out-of-Network)'],
  Hawaii: [
    'Hawaii Medical Assurance Association',
    'Hawaii Medical Services Association',
    'University Health Alliance',
    'Kaiser (Out-of-Network)',
  ],
  Idaho: ['Mountain Health CO-OP', 'PacificSource', 'Regence', 'SelectHealth'],
  Illinois: ['Health Alliance'],
  Indiana: ['CareSource', 'Indiana University Health Plans'],
  Iowa: ['CareSource', 'Indiana University Health Plans', 'Medica', 'Wellmark'],
  Louisiana: ['Vantage Health Plan', 'Magellan Health'],
  Maine: [
    'Maine Community Health Options',
    'Beacon Health Options',
    'Harvard Pilgrim',
    'Tufts Health Plan',
  ],
  Maryland: ['CareFirst', 'Kaiser (Out-of-Network)'],
  Massachusetts: [
    'AllWays Health Partners (MGBHP)',
    'Health New England',
    'Harvard Pilgrim',
    'Tufts Health Plan',
  ],
  Michigan: [
    'Health Alliance Plan of Michigan (HAP)',
    'Physicians Health Plan',
    'Priority Health',
  ],
  Minnesota: ['HealthPartners', 'Medica', 'PreferredOne', 'UCare'],
  Montana: ['PacificSource', 'Montana Health CO-OP'],
  Nevada: ['Hometown Health', 'Prominence Health Plan'],
  'New Hampshire': ['Harvard Pilgrim', 'Tufts Health Plan'],
  'New Jersey': ['AmeriHealth', 'Horizon', 'Oxford Health Plans'],
  'New Mexico': [
    'New Mexico Health Connections',
    'Presbyterian',
    'Magellan Health',
  ],
  'New York': [
    'Excellus BlueCross BlueShield',
    'Emblem Health',
    'Fidelis',
    'Highmark',
    'Oxford Health Plans',
  ],
  'North Dakota': ['Medica', 'Sanford Health Plan', 'Great-West Life'],
  Ohio: ['Medical Mutual'],
  Oregon: [
    'Moda Health',
    'PacificSource',
    'Providence',
    'Regence',
    'Kaiser (Out-of-Network)',
  ],
  Pennsylvania: [
    'Highmark',
    'Magellan Health',
    'Capital Blue Cross',
    'Independence Blue Cross',
    'University of Pittsburgh Medical Center',
  ],
  'Rhode Island': [
    'Brown University Student Health Insurance',
    'Neighborhood Health Plan of Rhode Island',
    'Tufts Health Plan',
  ],
  'South Carolina': ['Great-West Life'],
  'South Dakota': ['Medica', 'Sanford Health Plan', 'Wellmark'],
  Utah: ['Regence', 'SelectHealth'],
  Vermont: ['MVP Health Care', 'Great-West Life'],
  Virginia: ['CareFirst', 'Magellan Health', 'Kaiser (Out-of-Network)'],
  Washington: [
    'First Choice Health',
    'Premera',
    'Regence',
    'Kaiser (Out-of-Network)',
  ],
  'West Virginia': ['CareSource', 'The Health Plan', 'Highmark'],
  Wisconsin: ['Dean Health', 'Quartz'],
  Wyoming: ['Magellan Health'],
};

function getPayments(insurances: string[]) {
  const allInsurances = _.sortBy(
    _.uniq(_.compact(_.flatten(_.concat(nationalInsurances, insurances)))),
  );

  return ['Out-of-Pocket', 'Sliding Scale', ...allInsurances];
}

export function getStatePayments(state: string) {
  return getPayments(stateToInsurances[state]);
}

export function getUserPayments(userDoc: UserDoc) {
  const stateInsurances = _.flatten(
    _.map(userDoc.licensed_states, (state) => stateToInsurances[state]),
  );

  return getPayments(stateInsurances);
}

export const tests = [
  {
    title: 'Anxiety Test',
    subTitle:
      'Could you be experiencing generalized anxiety? This test could help you find out. The test used here is called the GAD-7 — a widely used screening tool for generalized anxiety in both clinical settings and in research.',
    directions: `Over the last 2 weeks, how often have you been bothered by the following problems?`,
    sources:
      'Spitzer RL, Kroenke K, Williams JB, Löwe B. A brief measure for assessing generalized anxiety disorder: the GAD-7. Arch Intern Med. 2006;166:1092-7. The GAD-7 was developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with a grant from Pfizer Inc.',
    slug: 'anxiety',
    url: '/tests/anxiety',
    questions: [
      'Feeling nervous, anxious or on edge.',
      'Not being able to stop or control worrying.',
      'Worrying too much about different things.',
      'Trouble relaxing.',
      'Being so restless that it is hard to sit still.',
      'Becoming easily annoyed or irritable.',
      'Feeling afraid as if something awful might happen.',
    ],
    allOptions: [
      { value: 0, label: 'Not at all' },
      { value: 1, label: 'Several days' },
      { value: 2, label: 'More than half the days' },
      { value: 3, label: 'Nearly every day' },
    ],
    results: [
      {
        min: 0,
        max: 4,
        label: 'Minimal Anxiety',
        description: [
          `You may experience minimal symptoms that correspond with generalized
            anxiety disorder. Though you may sometimes feel anxiety these
            experiences are not persistent and are unlikely to be a sign of a
            clinical condition.`,
        ],
      },
      {
        min: 5,
        max: 9,
        label: 'Mild Anxiety',
        description: [
          `You may experience mild symptoms that correspond with generalized
            anxiety disorder. Lifestyle adjustments like aerobic exercises and
            staying active can help.`,
          `Monitoring your symptoms over time and practicing relaxation
            techniques may also help.`,
        ],
      },
      {
        min: 10,
        max: 14,
        label: 'Moderate Anxiety',
        description: [
          `You may experience moderate symptoms that correspond with
            generalized anxiety disorder. Further assessment from a mental
            health professional could be helpful and is recommended if
            possible.`,
          `Lifestyle adjustments like aerobic exercises and practicing
            relaxation techniques can also help.`,
        ],
      },
      {
        min: 15,
        max: 21,
        label: 'Severe Anxiety',
        description: [
          `You may experience severe symptoms that correspond with
            generalized anxiety disorder. Further assessment from a mental
            health professional could be helpful and is strongly advised.`,
          `Lifestyle adjustments like aerobic exercises and practicing
            relaxation techniques can also help, but evaluation from a mental
            health professional is a priority if possible.`,
        ],
      },
    ],
  },
  {
    title: 'Depression Test',
    subTitle: `Could you be experiencing depression? This test could help you find out. The test used here is called the PHQ-9 — a widely used screening tool for depression in both clinical settings and in research.`,
    directions: `Over the last 2 weeks, how often have you been bothered by the following problems?`,
    sources:
      'Kroenke K, Spitzer RL. The PHQ-9: a new depression diagnostic and severity measure. Psychiatr Ann. 2002;32:509-21. The PHQ-9 was developed by Drs. Robert L. Spitzer, Janet B.W. Williams, Kurt Kroenke and colleagues, with a grant from Pfizer Inc.',
    slug: 'depression',
    url: '/tests/depression',
    questions: [
      'Little interest or pleasure in doing things.',
      'Feeling down, depressed or hopeless.',
      'Trouble falling asleep, staying asleep, or sleeping too much.',
      'Feeling tired or having little energy.',
      'Poor appetite or overeating',
      `Feeling bad about yourself - or that you're a failure or have let yourself or your family down.`,
      'Trouble concentrating on things, such as reading the newspaper or watching television.',
      'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual.',
      'Thoughts that you would be better off dead or of hurting yourself in some way.',
    ],
    allOptions: [
      { value: 0, label: 'Not at all' },
      { value: 1, label: 'Several days' },
      { value: 2, label: 'More than half the days' },
      { value: 3, label: 'Nearly every day' },
    ],
    results: [
      {
        min: 0,
        max: 4,
        label: 'None - Minimal',
        description: [
          `You may experience minimal symptoms that correspond with clinical
            depression. Though you may sometimes feel down these experiences are
            not persistent and are unlikely to be a sign of a clinical
            condition.`,
        ],
      },
      {
        min: 5,
        max: 9,
        label: 'Mild',
        description: [
          `You may experience mild symptoms that correspond with clinical
            depression. Lifestyle adjustments like regular sleep routines and
            social contact can help.`,
          `Watchful monitoring with follow up testing could also be helpful
            to see how your symptoms develop over time.`,
        ],
      },
      {
        min: 10,
        max: 14,
        label: 'Moderate',
        description: [
          `You may experience moderate symptoms that correspond with clinical
            depression. Further assessment from a mental health professional
            could be helpful and is worth considering if possible.`,
          `Lifestyle adjustments like regular sleep routines and social
            contact can also help.`,
        ],
      },
      {
        min: 15,
        max: 19,
        label: 'Moderately Severe',
        description: [
          `You may experience moderately severe symptoms that correspond with
            clinical depression. Further assessment from a mental health
            professional could be helpful and is advised.`,
          `Lifestyle adjustments like regular sleep routines and social
            contact can also help, but evaluation from a mental health
            professional is a priority if possible.`,
        ],
      },
      {
        min: 20,
        max: 27,
        label: 'Severe',
        description: [
          `You may experience severe symptoms that correspond with clinical
            depression. Further assessment from a mental health professional
            could be helpful and is strongly advised.`,
          `Lifestyle adjustments like regular sleep routines and social
            contact can also help, but evaluation from a mental health
            professional is a priority if possible.`,
        ],
      },
    ],
  },
];

export const testOptions = _.map(tests, ({ title, url }) => ({
  label: title,
  value: url,
}));

// 9 questions, 150+ answers
export const askTherapists = [
  {
    tag_id: 'feb24a0b-f855-49e2-8cea-e236880bb40b',
    pageTitle:
      'Why did you become a therapist, and what motivates you to continue?',
    seoTitle: 'Therapists Answer: Why I Became a Therapist',
    navTitle: 'Why did you become a therapist?',
    slug: 'why-i-became-a-therapist',
  },
  {
    tag_id: '1a619b7f-0ba9-4fe3-a021-ba4cee394d9a',
    pageTitle:
      'For people that are hesitant to try therapy, what do you wish you could tell them?',
    seoTitle: 'Therapists Answer: Advice for Starting Therapy',
    navTitle: 'Advice for starting therapy?',
    slug: 'advice-for-starting-therapy',
  },
  {
    tag_id: '94946732-30bf-4df4-8d7b-3fb77ce27371',
    pageTitle:
      'What should people thinking about therapy with you, know about you?',
    seoTitle: 'Therapists Answer: What Should Clients Know About Me',
    navTitle: 'What should clients know about you?',
    slug: 'what-are-therapists-like',
  },
  {
    tag_id: '8fd19cfc-0320-4556-ac2a-82870969919e',
    pageTitle:
      'What is therapy like? What does a typical session with you look like?',
    seoTitle: 'Therapists Answer: What is Therapy Like',
    navTitle: 'What is therapy like?',
    slug: 'what-is-therapy-like',
  },
  {
    tag_id: '096b55af-0434-4f31-be7e-07f13a1cc40e',
    pageTitle: 'How can teletherapy work? How can you provide care remotely?',
    seoTitle: 'Therapists Answer: How can Teletherapy work',
    navTitle: 'How can teletherapy work?',
    slug: 'how-can-teletherapy-work',
  },
  {
    tag_id: '927ef64c-88ea-4014-9b82-766333aa3ad0',
    pageTitle: 'How do you work with clients with anxiety?',
    seoTitle: 'Therapists Answer: How can you help with anxiety?',
    navTitle: 'How can you help with anxiety?',
    slug: 'anxiety',
  },
  {
    tag_id: '3f72e5fb-5307-485b-90fe-c1f71c1255cf',
    pageTitle: 'How do you work with clients with depression?',
    seoTitle: 'Therapists Answer: How can you help with depression?',
    navTitle: 'How can you help with depression?',
    slug: 'depression',
  },
  {
    tag_id: '623e93c9-0726-4e44-8735-a406a30eb097',
    pageTitle: 'How does Cognitive Behavior Therapy (CBT) work?',
    seoTitle: 'Therapists Answer: How does CBT work',
    navTitle: 'How does CBT work?',
    slug: 'cognitive-behavioral-cbt',
  },
  {
    tag_id: '0844f313-9d58-480b-9222-c13c6cfc9da5',
    pageTitle: 'How do you work with clients with trauma and PTSD?',
    seoTitle: 'Therapists Answer: How do you work with trauma?',
    navTitle: 'How do you work with trauma?',
    slug: 'trauma-and-ptsd',
  },
];

export function useSearchableIssues() {
  const typeDocs = useDocListSelector({
    collection: 'tag',
    filter: ({ category, label, is_introducing, is_deprecating }: TagDoc) =>
      category === 'Types' &&
      label !== 'Individual Therapy' &&
      !is_introducing &&
      !is_deprecating,
    order: ['position', 'asc'],
  });

  const issueDocs = useDocListSelector({
    collection: 'tag',
    filter: {
      category: 'Specialties',
      is_introducing: false,
      is_deprecating: false,
    },
    order: ['position', 'asc'],
  });

  return useMemo(() => [...typeDocs, ...issueDocs], [typeDocs, issueDocs]);
}

export function useSearchableApproaches() {
  const approachDocs = useDocListSelector({
    collection: 'tag',
    filter: ({ category, position, is_deprecating, is_introducing }: TagDoc) =>
      category === 'Approaches' &&
      position &&
      !is_deprecating &&
      !is_introducing,
    order: ['position', 'asc'],
  });

  return approachDocs;
}

function useCreateMaps(addUrl: Function) {
  const issueDocs = useSearchableIssues();
  const approachDocs = useSearchableApproaches();

  if (isProduction) {
    return;
  }

  if (!_.size(issueDocs) || !_.size(approachDocs)) {
    console.log('Error: docs not loaded.');
    return;
  }

  _.each(issueDocs, (issueDoc) => {
    addUrl(getTagUrl(issueDoc.label));
  });

  _.each(approachDocs, (approachDoc) => {
    addUrl(getTagUrl(approachDoc.label));
  });

  _.each(allStates, (state) => {
    addUrl(getStateUrl(state));
    addUrl(getStateUrl(state, 'online-therapy'));

    if (_.includes(tagStates, state)) {
      _.each(issueDocs, (issueDoc) => {
        addUrl(getStateUrl(state, issueDoc.slug));
      });

      _.each(approachDocs, (approachDoc) => {
        addUrl(getStateUrl(state, approachDoc.slug));
      });
    }

    if (_.includes(insuranceStates, state)) {
      _.each(getStatePayments(state), (payment) => {
        addUrl(getStateUrl(state, payment));
      });
    }
  });
}

export function useStateSitemap() {
  const urls: string[] = [];
  const addUrl = (path: string) =>
    urls.push(`<url><loc>https://mintleaf.co${path}</loc></url>`);

  useCreateMaps(addUrl);

  console.log(urls.join(' '));
}

export function useStateWhitelist() {
  const urls: string[] = [];
  const addUrl = (path: string) => urls.push(`'^${path}$',`);

  useCreateMaps(addUrl);

  console.log(urls.join(' '));
}

export const emailRegex = /\S+@\S+\.\S+/;
