import React, { Component } from 'react';
import _ from '@/lodash';
import { IoIosPlay, IoMdPause } from 'react-icons/io';
import styles from '../Recording/Recording.module.scss';

interface AudioProps {
  url?: string;
  audio_timeline?: number[];
}

interface AudioState {
  audioPaused: boolean;
  intervalId?: string;
}

class Audio extends Component<AudioProps> {
  state: AudioState = {
    audioPaused: true,
  };

  mediaRef?: any;
  canvasRef?: any;
  audioContainerRef?: any;

  constructor(props: AudioProps) {
    super(props);

    this.mediaRef = React.createRef();
    this.canvasRef = React.createRef();
    this.audioContainerRef = React.createRef();
  }

  componentDidMount() {
    const { audio_timeline } = this.props;

    drawAudioTimeline({
      canvasRef: this.canvasRef,
      audioContainerRef: this.audioContainerRef,
      audioTimeline: audio_timeline,
    });
  }

  togglePlayAudio = () => {
    const { current: media } = this.mediaRef;
    const { audioPaused } = this.state;

    if (audioPaused) {
      media.play();
      this.playAudioTimeline();
    } else {
      media.pause();
      this.clearInterval();
    }

    this.setState({ audioPaused: !audioPaused });

    media.onended = () => this.setState({ audioPaused: true });
  };

  playAudioTimeline = () => {
    const { audio_timeline } = this.props;
    const { current: media } = this.mediaRef;

    const intervalId = setInterval(() => {
      const currentTimeIndex = media.currentTime * 10;

      drawAudioTimeline({
        canvasRef: this.canvasRef,
        audioContainerRef: this.audioContainerRef,
        audioTimeline: _.take(audio_timeline, currentTimeIndex),
      });
    }, 100);

    this.setState({ intervalId });
  };

  clearInterval(callback?: any) {
    const { intervalId } = this.state;

    clearInterval(intervalId);

    this.setState({ intervalId: undefined }, callback);
  }

  render() {
    const { url } = this.props;
    const { audioPaused } = this.state;

    return (
      <div className={styles.recordingContainer}>
        <div>
          <div className={styles.audio} ref={this.audioContainerRef}>
            <audio ref={this.mediaRef} preload="auto" controls>
              <source src={url} type="audio/webm" />
            </audio>
          </div>

          <div className={styles.noAudio} onClick={this.togglePlayAudio}>
            {audioPaused ? <IoIosPlay /> : <IoMdPause />}
          </div>

          <canvas ref={this.canvasRef} className={styles.audioCanvas} />
        </div>
      </div>
    );
  }
}

export function drawAudioTimeline({
  canvasRef,
  audioContainerRef,
  audioTimeline,
}: {
  canvasRef: any;
  audioContainerRef: any;
  audioTimeline?: number[];
}) {
  const { current: canvas } = canvasRef;
  const { current: audioContainer } = audioContainerRef;

  if (canvas && audioContainer && audioTimeline) {
    const { clientWidth, clientHeight } = audioContainer;
    const context = canvas.getContext('2d');

    canvas.width = clientWidth;
    canvas.height = clientHeight;

    context.clearRect(0, 0, clientWidth, clientHeight);

    const dark350 = '#d7d3d2';
    context.fillStyle = dark350;

    _.each(audioTimeline, (loudness, index) => {
      const prev = audioTimeline[index - 1] || 0;
      const next = audioTimeline[index + 1] || 0;
      const smoothed = prev * 0.2 + loudness + next * 0.2;
      const scaled = smoothed * 600;
      const barHeight = Math.min(Math.max(scaled, 5), clientHeight);
      const barWidth = 6;
      const barMargin = 2;
      const time = _.size(audioTimeline) - index;
      const offset = time * (barWidth + barMargin);
      const x = clientWidth - offset;
      const y = clientHeight / 2 - barHeight / 2;

      fillRoundRect(context, x, y, barWidth, barHeight);
    });
  }
}

function fillRoundRect(
  ctx: any,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  const targetRadius = width / 2;
  const radius = height > targetRadius ? targetRadius : height;

  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fill();
}

export default Audio;
