import ReactSelect, { components } from 'react-select';

interface DropdownProps {
  options: { value: string; label: string }[];
  onChange: Function;
  buttonChildren?: any;
  value?: any;
  isDisabled?: boolean;
  minWidth?: number;
  isReverse?: boolean;
  ariaLabel?: string;
}

const Dropdown = (props: DropdownProps & any) => {
  const { buttonChildren, minWidth, isReverse, ariaLabel, ...passedProps } =
    props;

  const dark1 = 'oklch(99% 1% 165)';
  const dark3 = 'oklch(94% 1% 165)';
  const dark6 = 'oklch(60% 1% 165)';
  const dark8 = 'oklch(40% 1% 165)';
  const white = '#ffffff';

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {buttonChildren}
      </components.DropdownIndicator>
    );
  };

  return (
    <ReactSelect
      isClearable={false}
      maxMenuHeight={250}
      menuPortalTarget={document.body}
      components={{ DropdownIndicator }}
      styles={{
        container: () => ({
          display: 'inline-block',
        }),
        control: (provided: any, { isFocused }: any) => ({
          ...provided,
          borderRadius: 0,
          border: 'none',
          backgroundColor: 'none',
          boxShadow: 'none',
          minHeight: 'none',
        }),
        valueContainer: () => ({ height: 0, position: 'absolute' }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          ':hover': isReverse ? {} : { backgroundColor: dark1 },
          cursor: 'pointer',
          color: dark6,
          padding: '0 6px',
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          display: 'none',
        }),
        option: (provided: any, { isFocused }: any) => ({
          ...provided,
          backgroundColor: isFocused ? dark1 : white,
          ':active': { backgroundColor: dark1 },
          color: dark8,
          cursor: 'pointer',
        }),
        input: () => ({ height: 0, position: 'absolute', opacity: 0 }),
        placeholder: () => ({ display: 'none' }),
        menu: (provided: any) => ({
          ...provided,
          borderRadius: 2,
          backgroundColor: white,
          border: `1px solid ${dark3}`,
          boxShadow: 'none',
          minWidth: minWidth || 150,
        }),
        singleValue: () => ({ display: 'none' }),
      }}
      aria-label={props.ariaLabel || 'Dropdown'}
      {...passedProps}
    />
  );
};

export default Dropdown;
