import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import Tooltip from '@/components/Tooltip/Tooltip';
import { Button } from '@/components/Button/Button';
import Evil from '@/components/Evil/Evil';
import { fetchPost } from '@/hooks/useFetch';
import { HeaderTags } from '@/components/HeaderTags/HeaderTags';
import { loginCallback } from './loginHelpers';
import DefaultRedirect from '@/containers/DefaultRedirect/DefaultRedirect';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRobot, setIsRobot] = useState(false);

  const isFilled = Boolean(email && password);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isRobot) {
      setErrorMessage('Please check the box to confirm you are human.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    fetchPost('/auth/login', { username: email, password })
      .then((res) => loginCallback({ res, dispatch, navigate }))
      .catch(() => setErrorMessage('Email and password do not match.'))
      .finally(() => setIsLoading(false));
  };

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <HeaderTags
        title="Provider Login | MintLeaf"
        description="Log back in to your MintLeaf therapist profile."
        url="https://mintleaf.co/login"
      />

      <DefaultRedirect />

      <h1 className={styles.loginHeader}>Welcome Back</h1>

      <div className={styles.loginSubheader}>
        MintLeaf is your helper in growing your practice. Let us help you find
        clients that agree with your approach to mental health.
      </div>

      <div className={styles.loginFields}>
        <div className="form-field">
          <span className="form-label">Email or Username</span>
          <input
            name="email"
            type="text"
            className={styles.loginInput}
            placeholder="Your Email or Username"
            value={email}
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </div>

        <div className="form-field">
          <span className="form-label">Password</span>
          <input
            name="password"
            type="password"
            className={styles.loginInput}
            placeholder="Your Password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
          <div>
            <a href="/reset" className="link green form-label">
              Reset Password
            </a>
          </div>
        </div>
      </div>

      <Evil setIsRobot={setIsRobot} />

      {errorMessage && <div className={styles.loginError}>{errorMessage}</div>}

      <div className={styles.passwordRow}>
        <Tooltip
          content="Please fill out all fields and checkboxes."
          disabled={isFilled}
        >
          <Button
            className="btn"
            type="submit"
            disabled={isLoading || !isFilled}
          >
            Login
          </Button>
        </Tooltip>
      </div>

      <div className={styles.switchContainer}>
        <div>
          Not a member yet?
          <a href="/register" className="link green">
            Sign up
          </a>
        </div>
      </div>
    </form>
  );
}

export default Login;
