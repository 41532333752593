import _ from '@/lodash';
import styles from './Address.module.scss';
import profileStyes from '@/layouts/Profile/Profile.module.scss';
import modalStyles from '@/components/Modal/Modal.module.scss';
import { UserDoc } from 'src-server/models';
import { Link } from 'react-router-dom';
import { useIsMyself } from '@/redux/selectors';
import AddressForm from '../AddressForm/AddressForm';

interface AddressProps {
  userDoc: UserDoc;
}

function Address(props: AddressProps) {
  const { userDoc } = props;
  const {
    id,
    addressOne,
    addressTwo,
    city,
    state,
    zip,
    email_verified,
    licensed_states,
    email,
  } = userDoc;

  const isMyself = useIsMyself(id);
  const addressArray = _.compact([addressOne, addressTwo, city, state, zip]);
  const hasAddress = city || zip;

  if (!hasAddress && !isMyself) {
    return null;
  }

  const address = encodeURIComponent(_.join(addressArray, ' '));
  const googleLink = `https://www.google.com/maps/search/?api=1&query=${address}`;

  return (
    <>
      {isMyself && !email_verified && !_.includes(email, 'elucidate') && (
        <>
          <div className={profileStyes.sidebarHeader}>Email Verification</div>

          <div className={styles.addressContainer}>
            <Link className={styles.verifyLink} to="/verify">
              Verify my Email
            </Link>
          </div>
        </>
      )}

      <div className={profileStyes.sidebarHeader}>
        <span>{isMyself ? 'Practice Details' : 'Office Location'}</span>

        {isMyself && (
          <AddressForm
            buttonClassName={
              _.size(licensed_states) ? modalStyles.linkComplete : undefined
            }
          />
        )}
      </div>

      <div className={styles.addressContainer}>
        {!hasAddress && 'No listed office.'}

        {hasAddress && (
          <>
            {addressOne && <div>{addressOne}</div>}

            {addressTwo && <div>{addressTwo}</div>}

            <div>
              {city ? `${city}, ${state}` : state} {zip}
            </div>

            <a
              href={googleLink}
              className={styles.directions}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              Get Directions
            </a>
          </>
        )}
      </div>
    </>
  );
}

export default Address;
