import { Link, useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';
import _ from '@/lodash';
import { IoIosArrowForward } from 'react-icons/io';
import { useLoginSelector } from '@/redux/selectors';

function Footer() {
  const { pathname } = useLocation();

  const isProfile = _.includes(pathname, '/therapist/');
  const isBilling = pathname === '/billing';
  const isTherapistLanding = pathname === '/for-providers';
  const isClientLanding = pathname === '/therapists' || pathname === '/';
  const login = useLoginSelector();

  if ((isProfile && login?.id) || isBilling) {
    return null;
  }

  return (
    <>
      {isClientLanding && (
        <div className={styles.footerBanner}>
          <div>
            <div className={styles.footerBannerTitle}>
              Are you a mental health professional or group?
            </div>

            <div>
              Get all the resources you need to build a thriving therapy
              practice.
            </div>
          </div>

          <div>
            <Link className={styles.providerLearnMore} to="/for-providers">
              <span>Learn more about membership</span>
              <IoIosArrowForward />
            </Link>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <div>
          <div className={styles.footerLabel}>Main</div>
          {!isClientLanding && (
            <div>
              <Link to="/therapists">Find a Therapist</Link>
            </div>
          )}

          {!isTherapistLanding && (
            <div>
              <Link to="/for-providers">For Providers</Link>
            </div>
          )}

          <div>
            <Link to="/login">Login</Link>
          </div>
        </div>

        <div>
          <div>
            <div className={styles.footerLabel}>Self Tests</div>
          </div>
          <div>
            <Link to="/tests/anxiety">Anxiety Test</Link>
          </div>
          <div>
            <Link to="/tests/depression">Depression Test</Link>
          </div>
        </div>

        <div>
          <div>
            <div className={styles.footerLabel}>Ask Therapists</div>
          </div>
          <div>
            <Link to="/posts/what-is-therapy-like">What is Therapy Like?</Link>
          </div>
          <div>
            <Link to="/posts/advice-for-starting-therapy">
              Advice for Starting Therapy
            </Link>
          </div>
          <div>
            <Link to="/posts/how-can-teletherapy-work">
              How can Teletherapy Work?
            </Link>
          </div>
        </div>

        <div className="desktop-only">
          <div className={styles.footerLabel}>Follow Us</div>
          <div>
            <a
              href="https://www.instagram.com/mintleaf_therapy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/MintLeafTherapy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/mintleaf/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>

        <div>
          <div className={styles.footerLabel}>Company</div>
          <div>
            <a href="https://www.blog.mintleaf.co/">Our Blog</a>
          </div>

          <div>
            <a
              href="mailto:team@mintleaf.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </div>
          <div>
            <Link to="/legal/privacy-policy" rel="noopener noreferrer nofollow">
              Privacy
            </Link>
            {' | '}
            <Link to="/legal/terms-of-use" rel="noopener noreferrer nofollow">
              Terms
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
