import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { IoIosArrowDown, IoIosMenu } from 'react-icons/io';
import styles from './Navbar.module.scss';
import Modal from '@/components/Modal/Modal';
import classNames from 'classnames';
import _ from '@/lodash';
import { askTherapists, Option, testOptions, useDefaultLink } from '@/helpers';
import { useLoginSelector } from '@/redux/selectors';
import Dropdown from '@/components/Dropdown/Dropdown';
import ShareProfile from '@/containers/ShareProfile/ShareProfile';
import UpdateEmail from '@/containers/UpdateEmail/UpdateEmail';
import { fetchPost } from '@/hooks/useFetch';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@/redux/actions';

function Navbar() {
  const login = useLoginSelector();
  const defaultLink = useDefaultLink();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTherapistLanding = pathname === '/for-providers';
  const isClientLanding =
    _.includes(pathname, '/therapists') || pathname === '/';
  const isPosts = _.includes(pathname, '/posts/');
  const isTherapistSearch = _.includes(pathname, '/therapists/');
  const isTests = _.includes(pathname, '/tests/');
  const isNotFound = pathname === '/not-found';

  const isReverseBar =
    isTherapistLanding ||
    isClientLanding ||
    isPosts ||
    isTherapistSearch ||
    isTests ||
    isNotFound;

  const askTherapistOptions = _.map(askTherapists, ({ navTitle, slug }) => ({
    label: navTitle,
    value: `/posts/${slug}`,
  }));

  const onClickLogout = () => {
    fetchPost('/auth/logout', {});
    dispatch(logoutAction());
  };

  const onChangeDropdown = ({ value }: Option) => navigate(value);
  const activeClassName = ({ isActive }: any) =>
    isActive ? `${styles.navLink} ${styles.active}` : styles.navLink;

  const menuLinks = () => (
    <>
      {login?.id && (
        <>
          <div className="desktop-only">
            <ShareProfile
              buttonChildren={
                <div className={styles.navLink}>Share Profile</div>
              }
            />
          </div>

          <div className="desktop-only">
            <UpdateEmail
              buttonChildren={
                <div className={styles.navLink}>Update Email</div>
              }
            />
          </div>

          {/* <NavLink to="/billing"  className={activeClassName}>
            Account
          </NavLink> */}

          <NavLink to="/" className={activeClassName} onClick={onClickLogout}>
            Logout
          </NavLink>
        </>
      )}

      {!login?.id && (
        <>
          <NavLink className={activeClassName} to="/login">
            Login
          </NavLink>

          <div className="desktop-only">
            <Dropdown
              buttonChildren={
                <div className={styles.navLink}>
                  Self Tests <IoIosArrowDown />
                </div>
              }
              options={testOptions}
              onChange={onChangeDropdown}
              isReverse
            />
          </div>

          <div className="desktop-only">
            <Dropdown
              buttonChildren={
                <div className={styles.navLink}>
                  Ask Therapists <IoIosArrowDown />
                </div>
              }
              options={askTherapistOptions}
              onChange={onChangeDropdown}
              minWidth={280}
              maxMenuHeight={400}
              ariaLabel="Ask Therapists"
              isReverse
            />
          </div>

          {!isTherapistLanding && (
            <NavLink to="/for-providers" className={activeClassName}>
              For Providers
            </NavLink>
          )}

          {!isClientLanding && (
            <div className="mobile-only">
              <NavLink to="/therapists" className={activeClassName}>
                Find a Therapist
              </NavLink>
            </div>
          )}

          {!isClientLanding && (
            <div className="desktop-only">
              <NavLink to="/therapists" className={styles.navbarCTA}>
                Find a Therapist
              </NavLink>
            </div>
          )}

          <div className="mobile-only">
            <div className={styles.modalMenuHeader}>Self Tests...</div>

            {_.map(testOptions, ({ label, value }) => (
              <NavLink to={value} className={activeClassName} key={value}>
                {label}
              </NavLink>
            ))}
          </div>

          <div className="mobile-only">
            <div className={styles.modalMenuHeader}>Ask Therapists...</div>

            {_.map(askTherapistOptions, ({ label, value }) => (
              <NavLink to={value} className={activeClassName} key={value}>
                {label}
              </NavLink>
            ))}
          </div>
        </>
      )}
    </>
  );

  return (
    <div
      className={classNames(styles.navbar, {
        [styles.isReverseBar]: isReverseBar,
      })}
    >
      <Link to={defaultLink} className={styles.navBrand}>
        MintLeaf
      </Link>

      <div className={styles.navActions}>
        <div className="desktop-only">
          <div className={styles.desktopMenu}>{menuLinks()}</div>
        </div>

        <div className="mobile-only">
          <Modal
            buttonChildren={<IoIosMenu className={styles.mobileMenu} />}
            render={({ closeButton, closeModal }) => (
              <div className={styles.modalMenu} onClick={closeModal}>
                {closeButton}
                {menuLinks()}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
