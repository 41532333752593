import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './ChecklistOnboard.module.scss';
import { useCurrentUserSelector } from '@/redux/selectors';
import ProfileForm from '../ProfileForm/ProfileForm';
import { logoutAction } from '@/redux/actions';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import AddressForm from '../AddressForm/AddressForm';
import BadgeForm from '../BadgeForm/BadgeForm';

function ChecklistOnboard() {
  const dispatch = useDispatch();
  const userDoc = useCurrentUserSelector();

  if (!userDoc) {
    return null;
  }

  const { id, first_name, last_name, photo, licensed_states } = userDoc;
  const hasName = first_name && last_name;

  const onClick = () => {
    fetchPost('/api/user', { id, onboarded: true }, { dispatch });
  };

  const logout = () => {
    fetchPost('/auth/logout', {});
    dispatch(logoutAction());
  };

  return (
    <div className={styles.checklistPage}>
      <NavLink to="/" className={styles.checklistLogout} onClick={logout}>
        Logout
      </NavLink>

      <div className={styles.checklistContainer}>
        <div>
          <div className={styles.checklistHeader}>Welcome to MintLeaf</div>

          <div className={styles.checklistSubheader}>
            Set up should only take a few minutes, lets get started!
          </div>
        </div>

        <div className={styles.checklist}>
          <ProfileForm
            buttonClassName={hasName && styles.finished}
            buttonChildren={<>Fill out your name and contact information.</>}
          />

          <AddressForm
            buttonClassName={licensed_states ? styles.finished : ''}
            buttonChildren={<>Fill out where you are practicing.</>}
          />

          <BadgeForm
            buttonClassName={photo && styles.finished}
            buttonChildren={
              <>Add a profile photo to show clients (Recommended).</>
            }
          />
        </div>

        <Button
          className="btn"
          color="green"
          disabled={!hasName}
          onClick={onClick}
        >
          Next Step — Take me to my Profile
        </Button>
      </div>
    </div>
  );
}

export default ChecklistOnboard;
